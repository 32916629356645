import React, { useCallback, useContext, useEffect, useState } from 'react';
import { size } from 'lodash';
import Typist from 'react-typist';
import firebase from 'firebase/app';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { datadogLogs } from '@datadog/browser-logs';
// @ts-ignore
import { Button, PuzzleProviders, PuzzleViewer, PuzzleWrapper } from '@livingsecurity/cyberblocks';
// @ts-ignore
import { EvidenceLocker, ExamGuide } from '@livingsecurity/cyberpuzzles';

import { generateHash } from 'utils/string';
import { Instruction, PenaltyType, Puzzle } from '../../services/types';

import { useParticipants, useToggle } from 'hooks';
import { DB_KEY, PUZZLE_DB } from 'hooks/useFirebaseConnection/useFirebaseConnection';

import { GlobalContext } from '../../GameplayProvider';

import { Dialog } from 'components/Dialog';
import { VersionControl } from 'components/VersionControl/VersionControl';
import ConfirmSkipPuzzle from 'components/Modal/components/ConfirmSkipPuzzle';
import PuzzleInstructions from './PuzzleInstructions';
import PuzzleErrorComponent from './PuzzleErrorComponent';
import PuzzleWalkthrough from './PuzzleWalkthrough';

import 'react-typist/dist/Typist.css';

const useStyles = makeStyles((theme) => ({
  puzzleContainer: {
    width: '100%',
  },
  puzzlePlaceholder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    zIndex: 999,
    background: '#002638c7',
    textAlign: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  leaderPuzzleOverlay: {
    fontSize: '30px',
    cursor: 'pointer',
  },
  expandButtons: {
    position: 'absolute',
    top: '2px',
    right: '2px',
    background: 'rgba(0, 0, 0, .9)',
    padding: '9px 15px 6px',
    borderRadius: '50px',
    fontSize: '24px',
    cursor: 'pointer',
    zIndex: 99999,
    color: '#15ade6',
  },
  containerSmall: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '600px',
    minHeight: '480px',
    maxWidth: '1064px',
    maxHeight: '500px',
    boxShadow: 'var(--green-box-shadow)',
    margin: '0 auto',
    background: '#002638c7',
    [theme.breakpoints.down('sm')]: {
      minWidth: '400px',
      minHeight: '220px',
    },
    [theme.breakpoints.down('md')]: {
      minWidth: '580px',
    },
  },
  placeholderContainer: {
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '600px',
    minHeight: '455px',
    maxWidth: '1064px',
    maxHeight: '500px',
    boxShadow: 'var(--green-box-shadow)',
    margin: '0 auto',
    background: '#002638c7',
    [theme.breakpoints.down('sm')]: {
      minWidth: '400px',
      minHeight: '220px',
    },
    [theme.breakpoints.down('md')]: {
      minWidth: '580px',
    },
  },
  smallPuzzle: {
    top: '-160px',
    transform: 'scale(0.6)',
    position: 'relative',
    width: '100%',
    height: '100%',
    minWidth: '1000px',
    minHeight: '800px',
    margin: '0 -200px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    '& .active-file img': {
      width: '95%',
      height: 'auto',
    },
  },
  smallGuide: {
    top: '0',
    transform: 'scale(0.6)',
    position: 'relative',
    width: '100%',
    height: '100%',
    minWidth: '1000px',
    minHeight: '834px',
    margin: '0 -200px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    '& .active-file img': {
      width: '95%',
      height: 'auto',
    },
  },
  isHidden: {
    display: 'none !important',
  },
  placeholderImg: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    zIndex: 999,
    height: '100%',
  },
  puzzleLarge: {
    position: 'absolute',
    top: '10px',
    bottom: '10px',
    left: '15px',
    right: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 'auto',
    minHeight: 'auto',
    maxWidth: '2600px',
    maxHeight: '100%',
    boxShadow: 'var(--green-box-shadow)',
    margin: '0 auto',
    borderRadius: '10px',
    zIndex: 999,
    [theme.breakpoints.down('sm')]: {
      left: '5px',
      right: '5px',
      height: 'calc(100% - 20px)',
    },
  },
  noHeader: {
    top: 0,
    height: 'calc(100vh - 60px)',
    maxHeight: 'auto',
  },
  noHeaderSmall: {
    top: 30,
    height: 'calc(100vh - 135px)',
  },
  examGuideBtn: {
    padding: '8px 20px',
    margin: '5px',
    textAlign: 'center',
    cursor: 'pointer',
    background: '#002638c7',
    borderRadius: '3px',
    color: 'white',
    '&.active': {
      background: '#5999b1',
    },
    '&.hover': {
      background: '#003148',
    },
  },
  disabled: {
    pointerEvents: 'none',
    position: 'relative',
    '&:before': {
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 1000,
      width: '100%',
      height: '100%',
      background: '#003148',
      opacity: '.8',
      content: "''",
    },
  },
  restartButton: {
    color: '#121212',
    background: '#85E5FF',
    borderRadius: '4px',
    padding: '6px 24px',
    marginRight: '16px',
    zIndex: 1,
  },
  skipButton: {
    background: 'transparent !important',
    borderRadius: '4px',
    border: '1px solid #FFFFFF',
    padding: '6px 24px',
    marginRight: '5px',
    color: '#FFFFFF',
    zIndex: 1,
  },
  overlayDisabled: {
    position: 'absolute',
    '& *': {
      pointerEvents: 'none',
    },
  },
  puzzleWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  paddedWrapper: {
    marginTop: '42px',
    height: 'calc(100% - 42px) !important',
  },
}));

const VIEW_TYPES = {
  PUZZLE: 'PUZZLE',
  GUIDE: 'GUIDE',
};

export default function PuzzleContainer({
  puzzleData,
  callback,
  currentActivity,
}: {
  puzzleData: Puzzle;
  callback: Function;
  currentActivity: string;
}) {
  const context = useContext(GlobalContext);
  const classes = useStyles({
    isPuzzleExpanded: true,
  });
  const { t } = useTranslation();
  const isLeader = context.isLeader;
  const identity = context.identity;
  const { isSessionHosted } = context;
  const [currentView, toggleView] = useState(VIEW_TYPES.PUZZLE);
  const [loaded, setLoaded] = useState(false);
  const [confirmSkip, setConfirmSkip] = useState(false);
  const [puzzleSkiping, setPuzzleSkiping] = useState(false);
  const [isPuzzleSkipped, setIsPuzzleSkipped] = useState(false);
  const [puzzleHash, setPuzzleHash] = useState<number>();
  const participants = useParticipants();
  const isLeaderAlone = context.isLeader && participants.length === 0;
  const [dialogOpen, toggleDialog] = useToggle(false);
  const roomId = `${context.roomId}-${puzzleData.puzzleType[0]}`;
  const { stepData } = context.gameDef.activeStep;
  const { puzzleResource, instructions } = context.contentfulData;
  const puzzleInstructions: Array<Instruction> = puzzleData.instructions?.map(
    (contentfulInstruction) => contentfulInstruction.fields,
  );
  const [isPuzzleRestarting, setIsPuzzleRestarting] = useState(false);

  const [puzzleResultIsSent, setPuzzleResultIsSent] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setIsPuzzleSkipped(false);
  }, [currentActivity]);

  useEffect(() => {
    if (context.gameDef.activeStep.loop > 0) {
      toggleDialog();
    }

    setTimeout(() => {
      setLoaded(true);
    }, 1000);

    if (!puzzleHash) {
      setPuzzleHash(generateHash(puzzleData.title));
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    const hashTitle = generateHash(puzzleData.title);
    if (hashTitle !== puzzleHash) {
      setPuzzleHash(hashTitle);
      setPuzzleResultIsSent(false); // Reset if multiple puzzles are back-to-back
      setTimeout(() => {
        setLoaded(true);
      }, 1000);
    }
  }, [puzzleData.title]);

  useEffect(() => {
    if (!stepData.puzzleExpanded && stepData.puzzleExpanded) {
      toggleView(VIEW_TYPES.PUZZLE);
    }
    if (isLeader && currentView === VIEW_TYPES.GUIDE) {
      toggleView(VIEW_TYPES.PUZZLE);
    }
  }, [stepData, isLeader]); // eslint-disable-line

  useEffect(() => {
    if (!isLeader && confirmSkip) {
      setConfirmSkip(false);
    }
  }, [isLeader, confirmSkip]);

  const startPuzzle = useCallback(() => {
    context.gameService.togglePuzzleSize(!stepData.puzzleExpanded);
  }, [context.gameService.togglePuzzleSize, stepData.puzzleExpanded]);

  const onSkipClick = useCallback(() => {
    if (isPuzzleSkipped) return;

    setConfirmSkip(true);
  }, [setConfirmSkip, isPuzzleSkipped]);

  const cancelSkip = useCallback(() => {
    setConfirmSkip(false);
  }, [setConfirmSkip]);

  const skipPuzzle = useCallback(() => {
    // Record penalty
    if (!isLeader) return;

    setPuzzleSkiping(true);

    context.gameService
      .recordPenalty(PenaltyType.PUZZLE)
      .then(() => {
        setIsPuzzleSkipped(true);
        datadogLogs.logger.info(
          `Puzzle was skipped. Room ID: ${roomId}. Current Leader: ${identity} (${context.currentLeader}). Puzzle Hash: ${puzzleHash}.`,
        );
        setTimeout(() => {
          callback();
        }, 500);
      })
      .catch((e) => {
        enqueueSnackbar(e.message);
        console.error(`Error skipping puzzle: ${e}`);
        setIsPuzzleSkipped(false);
      })
      .finally(() => {
        setPuzzleSkiping(false);
        setConfirmSkip(false);
      });
  }, [context.gameService, callback]);

  const restartPuzzle = useCallback(() => {
    if (isPuzzleRestarting) return;

    setIsPuzzleRestarting(true);

    context.gameService.togglePuzzleSize(false).then(() => {
      context.gameService.clearPuzzleState(roomId);
    });
    setLoaded(false);

    if (isLeader) {
      datadogLogs.logger.info(
        `Puzzle being restarted. Room ID: ${roomId}. Current Leader: ${identity} (${context.currentLeader}). Puzzle Hash: ${puzzleHash}.`,
      );
    }

    setTimeout(() => {
      setLoaded(true);
      setIsPuzzleRestarting(false);
    }, 1000);
  }, [roomId, context, setLoaded]);

  const handlePuzzleEnding = useCallback(
    (didPass: boolean) => {
      if (puzzleResultIsSent || !isLeader) return;

      datadogLogs.logger.info(
        `Puzzle attempting to advance (Pass: ${didPass}). Room ID: ${roomId}. Current Leader: ${identity} (${context.currentLeader}). Puzzle Hash: ${puzzleHash}.`,
      );
      if (didPass) {
        callback();
        setPuzzleResultIsSent(true);
      } else {
        restartPuzzle();
      }
    },
    [roomId, context, isLeader, callback, restartPuzzle, puzzleResultIsSent],
  );

  const reloadPuzzle = useCallback(() => {
    datadogLogs.logger.error(
      `Puzzle error required reload by ${identity}. Room ID: ${roomId}. Puzzle Hash: ${puzzleHash}.`,
    );
    window.location.reload();
  }, []);

  function renderOverlay() {
    if (!stepData.puzzleExpanded) {
      if (isLeader) {
        return (
          <div className={classes.puzzlePlaceholder} onClick={startPuzzle}>
            <div className={classes.leaderPuzzleOverlay}>{t('gameplay:puzzle:click-to-start')}</div>
            <div className={classes.leaderPuzzleOverlay} style={{ marginTop: '20px' }}>
              <i className="fas fa-play" />
            </div>
          </div>
        );
      } else {
        return (
          <div className={classes.puzzlePlaceholder}>
            <Typist startDelay={0} cursor={{ show: false }}>
              <div>{t('gameplay:puzzle:standby')}</div>
              <div>{t('gameplay:puzzle:waiting-for-leader')}</div>
              <div>{t('gameplay:puzzle:to-begin')}</div>
            </Typist>
          </div>
        );
      }
    }
  }

  function getPlaceholderImage() {
    const { placeholderImage } = puzzleData;

    if (placeholderImage) {
      const src = `https:${placeholderImage.fields.file.url}?w=600`;
      const img = new Image();
      img.src = src;
      return src;
    }

    switch (puzzleData?.puzzleType[0]) {
      case 'HOTSPOT':
        return 'https://puzzles.cdn.livingsecurity.com/ExamGuide/EntranceExam/SpotPatrol.jpg';
      case 'VISHING':
        return 'https://puzzles.cdn.livingsecurity.com/ExamGuide/EntranceExam/Vishing1.jpg';
      case 'CRAFT_PHISH':
        return 'https://puzzles.cdn.livingsecurity.com/ExamGuide/EntranceExam/GeneralBEC.jpg';
      case 'DATING_GAME':
        return 'https://puzzles.cdn.livingsecurity.com/ExamGuide/EntranceExam/hygiene.jpg';
      case 'UNSCRAMBLE':
        return 'https://puzzles.cdn.livingsecurity.com/ExamGuide/EntranceExam/EmojiPass.jpg';
      case 'REORDER':
        return 'https://puzzles.cdn.livingsecurity.com/ExamGuide/EntranceExam/ReOrder.jpg';
      case 'CORKBOARD':
        return 'https://puzzles.cdn.livingsecurity.com/ExamGuide/EntranceExam/HighLevel.jpg';
      case 'RESPONSE':
        return 'https://puzzles.cdn.livingsecurity.com/ExamGuide/EntranceExam/React.jpg';
      case 'IMAGE_COMPLETE':
        return 'https://puzzles.cdn.livingsecurity.com/ExamGuide/CriticalMass/complete1.jpg';
      case 'CLASSIFY':
        return 'https://puzzles.cdn.livingsecurity.com/ExamGuide/CriticalMass/classify1.jpg';
      case 'CAMERA_FEED':
        return 'https://puzzles.cdn.livingsecurity.com/ExamGuide/CriticalMass/feed1.jpg';
      default:
        return undefined;
    }
  }

  if (!loaded) {
    getPlaceholderImage();
    return null;
  }

  const commonProps = {
    embedded: true,
    t,
    name: puzzleResource?.displayName,
    theme: puzzleResource?.theme,
    ...puzzleResource?.resources,
  };
  return (
    <>
      <div
        className={clsx({
          [classes.puzzleLarge]: stepData.puzzleExpanded,
          [classes.placeholderContainer]: !stepData.puzzleExpanded,
          [classes.disabled]: confirmSkip,
          [classes.overlayDisabled]: confirmSkip,
        })}
      >
        {/* If the leader is playing with other people, the shouldn't see resources. Also hide if there are none */}
        <PuzzleViewer.Wrapper
          exerciseOnly={(size(context.gameDef.players) !== 1 && isLeader) || !puzzleResource?.name}
          resourceText={puzzleResource?.displayName || t('gameplay:puzzle:exam-guide')}
          exerciseText={t('gameplay:puzzle:challenge')}
        >
          <PuzzleViewer.Header>
            {isLeader && (
              <>
                <Button variant="contained" color="primary" classes={classes.restartButton} onClick={restartPuzzle}>
                  {t('gameplay:puzzle:restart')}
                </Button>
                <Button variant="outlined" classes={classes.skipButton} onClick={onSkipClick}>
                  {t('gameplay:puzzle:skip')}
                </Button>
              </>
            )}
            {puzzleData?.instructions && (
              <div>
                <PuzzleInstructions puzzleInstructions={puzzleInstructions} generalInstructions={instructions} />
              </div>
            )}
          </PuzzleViewer.Header>
          <PuzzleViewer.ExerciseWindow title={t('gameplay:puzzle:challenge')}>
            <VersionControl
              className={clsx(classes.puzzleWrapper, { [classes.paddedWrapper]: !isLeaderAlone && isLeader })}
            >
              <PuzzleWrapper
                firebaseConfig={{
                  firebase,
                  key: DB_KEY,
                  directory: PUZZLE_DB,
                }}
                load={() =>
                  import('./PuzzleComponent')
                    .then((module) => {
                      console.log('Loaded module:', module);
                      if (module && typeof module.default === 'function') {
                        return module.default;
                      } else {
                        console.error('PuzzleComponent does not have a valid default export');
                        throw new Error('Invalid PuzzleComponent export');
                      }
                    })
                    .catch((error) => {
                      console.error('Failed to load PuzzleComponent:', error);
                      throw error;
                    })
                }
                roomId={`${roomId}-${puzzleHash}`}
                provider={PuzzleProviders.FIREBASE}
                puzzleExpanded={stepData.puzzleExpanded}
                puzzleContent={puzzleData}
                isLeader={isLeader}
                errorComponent={PuzzleErrorComponent}
                reloadCallback={reloadPuzzle}
                puzzleVariation={puzzleData.puzzleVariation}
                handlePuzzleEnding={handlePuzzleEnding}
                puzzleState={{}}
              />
            </VersionControl>
          </PuzzleViewer.ExerciseWindow>
          {puzzleResource && (
            <PuzzleViewer.ResourcesWindow
              title={puzzleResource?.displayName || t('gameplay:puzzle:exam-guide')}
              withZoom
            >
              {puzzleResource?.resourcesType === 'DESKTOP' ? (
                <EvidenceLocker {...commonProps} />
              ) : (
                <ExamGuide {...commonProps} />
              )}
            </PuzzleViewer.ResourcesWindow>
          )}
        </PuzzleViewer.Wrapper>
        {!stepData.puzzleExpanded && (
          <img
            className={classes.placeholderImg}
            src={getPlaceholderImage()}
            alt={t('gameplay:puzzle:placeholder')}
            width={600}
          />
        )}
        {!stepData.puzzleExpanded && renderOverlay()}
      </div>
      {stepData.puzzleExpanded && <PuzzleWalkthrough toggleView={toggleView} />}
      {confirmSkip && <ConfirmSkipPuzzle cancelHandler={cancelSkip} skipHandler={skipPuzzle} loading={puzzleSkiping} />}
      {!isLeaderAlone && dialogOpen && !isSessionHosted && (
        <Dialog
          title={t('gameplay:puzzle:heads-up')}
          okText={t('gameplay:puzzle:got-it')}
          open={dialogOpen}
          onClose={() => toggleDialog(false)}
          maxWidth="sm"
          fullWidth={false}
        >
          {t('gameplay:puzzle:switch-leader')}
        </Dialog>
      )}
    </>
  );
}
